import * as React from "react"
// import { Link } from "gatsby"


// markup
const NotFoundPage = (props: any) => {
  return (
    <main >
     
    </main>
  )
}

export default NotFoundPage
